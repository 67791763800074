export const locationUtils = {
    async getCurrentLocation () {
        let location = await this.returnCurrentLocation().catch((error) => {
            console.error('Error getting current location', error);
        });
        return {lon: location.longitude, lat: location.latitude}; // aktuelle Koordinaten
        //return {lat: 48.71180776236015, lon: 9.388372081644336}; // Koordinaten von Deizisau (Vorführung für regionale Posts)
    },
    async returnCurrentLocation() {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        resolve({ latitude, longitude });
                    },
                    (error) => {
                        reject(error);
                    }
                );
            } else {
                reject(new Error('Geolocation is not supported by this browser.'));
            }
        });
    }
}
import React, { useState } from 'react';

const NewPostForm = ({ addPost }) => {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (text.trim() && title.trim()) {
            addPost(title, text);
            setText('');
            setTitle('');
        }
    };

    return (
        <form className="new-post-form" onSubmit={handleSubmit}>
            <textarea
                className="single-row"
                rows={1}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titel"
                maxLength={50}
            />
            <div className="textarea-container">
                <textarea
                    rows={15}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Schreibe einen neuen Jodel..."
                    maxLength={500}
                />
                <div className="char-count-container">
                    <span className={text.length === 500 ? "char-count max-char-count" : "char-count"}>
                        {text.length} / 500
                    </span>
                </div>
            </div>
            <button type="submit">Posten</button>
        </form>
    );
};

export default NewPostForm;

import axios from "axios"
import {locationUtils} from "../utils/location";
import keycloak from "../Authentification/Keycloak";
import getConfig from "../config/config";

const config = getConfig();
const path = config.REACT_APP_API || process.env.REACT_APP_API;

export const postApi = {
    async getPostsByCoords (lat, lon) {
        if (!keycloak.authenticated) return [];
        return (await axios.get(`${path}/posts/${lat}/${lon}`)).data;
    },
    async getPostByID (id) {
        if (!keycloak.authenticated) return [];
        return (await axios.get(`${path}/post/${id}`)).data;
    },
    async createNewPost(title, content, parent = null) {
        const location = await locationUtils.getCurrentLocation();
        if(location.lon && location.lat) {
            if(parent !== null) {
                await axios.post(`${path}/posts`, {
                    "authorID": 1,
                    "title": title,
                    "content": content,
                    "date": Date.now().toString(),
                    "location": {
                        "longitude": location.lon,
                        "latitude": location.lat,
                    },
                    "parent": parent
                });
            } else {
                await axios.post(`${path}/posts`, {
                    "authorID": 1,
                    "title": title,
                    "content": content,
                    "date": Date.now().toString(),
                    "location": {
                        "longitude": location.lon,
                        "latitude": location.lat,
                    }
                });
            }
        } else {
            console.log("Geolocation is not supported by this browser. Could'nt post without valid location");
        }
    },
    async reactToPost(id, reaction) {
        await axios.patch(`${path}/post/${id}`, {reaction: reaction});
    }
}
import Keycloak from "keycloak-js";
import getConfig from "../config/config";

const config = getConfig();

const keycloak = new Keycloak({
    url: config.REACT_APP_KC || process.env.REACT_APP_KC,
    realm: "Jodel",
    clientId: "jodel-frontend",
});

keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {});

export default keycloak;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretUp,
    faCaretDown,
    faComments,
    faAngleUp,
    faAngleDown
} from '@fortawesome/free-solid-svg-icons';
import {postApi} from "../api/posts.api";
import keycloak from "../Authentification/Keycloak";

const Post = ({ post, recursionDepth, selectPost, reload }) => {

    const [showComments, setShowComments] = React.useState(false);

    const upvote = () => {
        if (post.reaction === true) {
            revokeVote();
            return;
        }
        postApi.reactToPost(post.id, true).then(reload);
    }
    const downVote = () => {
        if (post.reaction === false) {
            revokeVote();
            return;
        }
        postApi.reactToPost(post.id, false).then(reload);
    }
    const revokeVote = () => {
        postApi.reactToPost(post.id, null).then(reload);
    }

    const getComments = (post, recursionDepth) => {
        let nrOfComments = post.comments.length;
        if (recursionDepth !== 0) post.comments.forEach(comment => {
            nrOfComments += getComments(comment, recursionDepth - 1);
        })
        return nrOfComments;
    }

    const getDate = (dateStr) => {
        let date = new Date(dateStr);
        return date.toLocaleDateString('de-DE') + "\n" + ("00" +  date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
    }

    return (
        <div>
            <div className="post" onClick={click => {if (!(click.target instanceof HTMLButtonElement)) setShowComments(!showComments)}}>
                <div className="post-container">
                    <div className="post-title">
                        <h3>{post.id === post.parent ? post.title : "Kommentar"}<span style={{color: 'gray'}}> von {post.anonymousID === 0 ? keycloak.idTokenParsed.preferred_username : "Anonymous " + post.anonymousID}</span></h3>
                    </div>
                    <div className="post-content">
                        <p>{post.content}</p>
                    </div>
                </div>
                {recursionDepth !== 0 && <div className="nrOfComments">
                    <FontAwesomeIcon className="icon" icon={faComments}/>
                    <span className="">{getComments(post, recursionDepth - 1)}</span>
                    <button onClick={() => selectPost(post)}>Antworten</button>
                </div>}
                <div className="post-right">
                <div className="post-date">
                    {getDate(post.date)}
                </div>
                <div className="row">
                    <div className="votes">
                        <button onClick={(e) => {
                            e.stopPropagation();
                            upvote();
                        }}>
                            {post.reaction === true ? <FontAwesomeIcon icon={faCaretUp}/> : <FontAwesomeIcon icon={faAngleUp} />}
                        </button>
                        <span>{post.reactions.positive - post.reactions.negative}</span>
                        <button onClick={(e) => {
                            e.stopPropagation();
                            downVote();
                        }}>
                            {post.reaction === false ? <FontAwesomeIcon icon={faCaretDown}/> : <FontAwesomeIcon icon={faAngleDown} />}
                        </button>
                    </div>
                </div>
                {recursionDepth !== 0 && <div className="spacer"></div>}
            </div>
        </div>
        <div className="comment">
        {recursionDepth !== 0 && showComments && post.comments.map(post =>
            <Post key={post.id} post={post} recursionDepth={recursionDepth - 1} selectPost={selectPost} reload={reload} />
        )}
        </div>
    </div>
    );
};

export default Post;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from "axios";
import keycloak from "./Authentification/Keycloak";

axios.interceptors.request.use(
    (config) => {
        if (keycloak.token) {
            config.headers['Authorization'] = `Bearer ${keycloak.token}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
